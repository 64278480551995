import React from "react";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "October 2016 – March 2020",
      title: "Data Analyst",
      place: "University of Economics in Katowice",
      desc: "",
    },
  ];

  const certificateDetails = [
    {
      title: "Using SAS Software in Data Engineering",
      institution: "University of Economics in Katowice",
      desc: "",
    },
    {
      title: "AML Certificate",
      institution: "General Inspectorate of Financial Information",
      desc: "",
    },
    {
      title: "Basics of Python",
      institution: "ING Poland",
      desc: "",
    },
    {
      title: "AI_dev3 Agents (Upcoming Course)",
      institution: "AI_devs & BRAVE Courses",
      desc: "",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "April 2022 – Present",
      title: "Python/Django Developer",
      place: "Useme",
      desc: "Responsible for developing and maintaining web applications, with a focus on backend architecture, performance optimization, and ensuring production stability. Involved in database management, process automation, and delivering solutions that align with business goals.",
    },
    {
      yearRange: "March 2020 – February Present",
      title: "Founder",
      place: "Better Nerf Technology",
      desc: "Developed a data scraping and automated cold email system with personalized templates, which allowed us to secure key projects. This included automating and updating over 30 Excel spreadsheets, implementing a system for conducting exams at an academic university, and creating a calculator for a photovoltaic company.",
    },
    {
      yearRange: "March 2020 – January 2022",
      title: "Tutor",
      place: "E-korepetycje",
      desc: "Guided students through exam preparation, leading to success in over 250 exams. Designed and supervised the development of more than 250 projects, ensuring a deep understanding of concepts and practical application.",
    },
    {
      yearRange: "October 2019 – June 2021",
      title: "Data Analyst",
      place: "ING Poland",
      desc: "Responsible for loading, extracting, and validating alert data. Performed data entry, data auditing, created data reports, and monitored data for accuracy. Manipulated, cleansed, and processed data using Excel, Cognos BI, and SQL. Updated processes to align with Global ING standards. Automated repetitive processes using Python scripts.",
    },
    {
      yearRange: "August 2019 – September 2019",
      title: "Process Monitoring and Control (KYC)",
      place: "ING Poland",
      desc: "Processed and supported KYC and AML processes. Conducted quality checks and assurance. Created reports using Power BI and Cognos BI.",
    },
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
            {/* Certificates Section */}
            <h2 className="text-7 fw-600 mb-4 pb-2 mt-5">Certificates</h2>
            <div className="border-start border-2 border-primary ps-3">
              {certificateDetails.map((cert, index) => (
                  <div key={index}>
                    <h3 className="text-5">{cert.title}</h3>
                    <p className="mb-2">{cert.institution}</p>
                    <p className="text-muted">{cert.desc}</p>
                    <hr className="my-4" />
                  </div>
              ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resume;
